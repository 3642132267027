import * as React from 'react';
import Container from '@mui/material/Container';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Pagination,
    TextField,
    ToggleButton,
    ToggleButtonGroup, Typography
} from "@mui/material";
import {Link, useNavigate} from 'react-router-dom';
import Grid from "@mui/material/Grid";
import axios from "axios";
import {site_url} from "../lib/global";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

export default function Upload(props) {
    const [date, setDate] = React.useState(null);
    React.useEffect(() => {
        props.setLoading(false);
    }, []);

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        let data = new FormData(event.currentTarget);
        data.append('date', date);
        axios
            .post(site_url + '/vsapi/v1/upload', data)
            .then((res) => {
                console.log('yes')
            })
            .catch(function (error) {
            });
    };

    const onDateChange = (value) => {
        setDate(value);
    }

    return (
        <main>
            <Container sx={{py: 2}} maxWidth="lg">
                { props.signData.upload ?
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
                        <input
                            style={{display: "none"}}
                            id="contained-button-file"
                            type="file"
                            name="file"
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span">
                                Upload
                            </Button>
                        </label>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            label="Title"
                            name="title"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="song"
                            label="Song"
                            name="song"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="vup"
                            label="Singer"
                            name="vup"
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="Date"
                                inputFormat="YYYY-MM-DD"
                                value={date}
                                onChange={onDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="thumbnail"
                            label="Thumbnail"
                            name="thumbnail"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >Upload</Button>
                    </Box> :
                    <Box sx={{mt: 1}}>
                        <Typography>Due to server limit, you need to send a email to admin@v-sing.com to apply for using upload function.</Typography>
                        <Typography>由于服务器性能限制，投稿功能不对外开放。如果你希望参与投稿工作，请发送邮件至 admin@v-sing.com 申请使用投稿功能。请在邮件中提及你的本站用户名。</Typography>
                        {/* <ul className="list">
                            <li>请在发送前确认你已经有在B站投稿过Vup直播翻唱剪辑。</li>
                            <li>请确认你清楚本站为方便管理，将会在数据库存储你的邮箱地址。请不要在本站使用和你邮箱密码相同的密码，以防止可能产生的损失。</li>
                            <li>请确认你在投稿前能保证对自己的录制进行检查，确认录音无掉帧漏帧跳帧或是卡顿的问题。</li>
                            <li>请确认你清楚本站不接受任何带版权音乐的转载。</li>
                            <li>请确认你清楚本站服务器位于美国，且不会考虑在中华人民共和国备案。因此，本站将不会尝试解决在可预见的未来发生的由于中国大陆网关限制导致中国大陆用户无法访问的问题。</li>
                            <li>请确认你清楚你上传的所有文件都不可以被你主动删除，且将会被任何互联网用户无限制下载。（你可以通过邮件申请删除投稿。另外不合格的投稿会被管理员主动删除。请不要恶意投稿，否则将会被封禁，包括但不限于账户、IP地址、地理位置和用户特征的封禁。）</li>
                            <li>请确认你清楚本站不要求任何用户个人信息，除非你通过投稿方式主动暴露个人信息，请不要这么做。</li>
                            <li>请确认你清楚本站为个人站。本站不对本站的服务做出任何保证。本站也不对本站任何管理行为做出提前通知和解释。本站保留一切情况下的最终解释权。</li>
                        </ul> */}
                    </Box>
                }
            </Container>
        </main>
    );
}